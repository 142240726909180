import { host } from "./utils/location";

export default {
  region: "ap-northeast-1",
  IdentityPoolId: "ap-northeast-1:9fdd59b6-5255-4764-8072-60b57ce5b991",
  UserPoolId: "ap-northeast-1_HGPKeXWL7",
  UserPoolArn:
    "cognito-idp:ap-northeast-1:424715544853:userpool/ap-northeast-1_HGPKeXWL7",
  ClientId: "5e7jlr8g8foolktp771p9u6kqs",
  apiURL: host
};

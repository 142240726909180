var storage =
  window.navigator.doNotTrack || window.navigator.msDoNotTrack
    ? window.sessionStorage
    : window.localStorage;
var validDate = false;

export function saveToken({ token, level }) {
  storage.setItem("token", token);
  //期限は２週間
  let date = Date.now() + 100 * 60 * 60 * 24 * 14;
  storage.setItem("expiration-date", date);
  storage.setItem("level", level);
}

export function getTokenObject() {
  try {
    let token = getToken(),
      level = storage.getItem("level") || 1;
    // このオブジェクトはストアのuserのデフォルトとしてつかう
    return {
      token,
      isLoggedIn: true,
      // ストレージはストリングだから、ダブルイコール
      isAdmin: level == 9, // eslint-disable-line eqeqeq
      isEditor: level == 2 // eslint-disable-line eqeqeq
    };
  } catch (error) {
    removeToken();
    return validDate
      ? { loginError: error, isLoggedIn: false }
      : { isLoggedIn: false };
  }
}

export function getToken() {
  if (checkToken()) {
    return storage.getItem("token");
  } else {
    removeToken();
    let error = new Error(
      "ログイン情報の期限切れました。またログインしてください。"
    );
    error.name = "TokenError";
    throw error;
  }
}

export function removeToken() {
  storage.removeItem("token");
  storage.removeItem("expiration-date");
  storage.removeItem("level");
}

function checkToken() {
  let token = storage.getItem("token");
  validDate = storage.getItem("expiration-date") > Date.now();

  return !!token && validDate;
}

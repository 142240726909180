import React, { Fragment } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { handleLogout } from "../pages/login/Login.actions.js";
import HeaderLogo from "../assets/logo_marketprice.png";

const Header = ({ isAdmin, isEditor, handleLogout }) => (
  <header className="App-header">
    <h1 className="header_logo">
      <img alt="logo" src={HeaderLogo} />
    </h1>
    <nav>
      <ul>
        <li>
          <NavLink exact to="/" activeClassName="selected">
            価格を検索する
          </NavLink>
        </li>
        {(isEditor || isAdmin) && (
          <li>
            <NavLink exact to="/admin/" activeClassName="selected">
              価格を更新する
            </NavLink>
          </li>
        )}
        {isAdmin && (
          <li>
            <NavLink exact to="/settings/" activeClassName="selected">
              各種設定
            </NavLink>
          </li>
        )}
        <li>
          <button className="btn_small_narrow_thin" onClick={handleLogout}>
            ログアウト
          </button>
        </li>
      </ul>
    </nav>
  </header>
);

const mapState = ({ user: { isAdmin, isEditor } }) => ({ isAdmin, isEditor });

export default connect(mapState, { handleLogout })(Header);

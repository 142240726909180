import React from "react";

import "../css/spinner.css";

/** http://tobiasahlin.com/spinkit/
 * ただのCSSアニメーションで回してる
 * TODO HOCに変化する？
 * TODO CSS transitionで優しくする(opacityなど)
 */
const Spinner = () => (
  <div className="spinner">
    <div className="bounce1" />
    <div className="bounce2" />
    <div className="bounce3" />
  </div>
);

export default Spinner;

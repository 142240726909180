import React, { Fragment } from "react";
import { Link, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import AsyncComponent from "../../utils/AsyncComponent.js";
import Spinner from "../../components/Spinner.js";
import { authRequestThunk } from "./Login.actions.js";
import "../../css/login.css";
import HeaderLogo from "../../assets/logo_marketprice.png";
const ResetPass = AsyncComponent(() => import("./ResetPass.js"));

// ----------------- main form component -------------------- //
const LoginForm = connect(
  ({ user: { loginError, loginPending } }) => ({ loginError, loginPending }),
  {
    auth: authRequestThunk
  }
)(({ loginError, auth, loginPending }) => {
  function handleLogin(e) {
    e.preventDefault();
    // check to the API
    auth({
      username: e.target.username.value.trim(),
      password: e.target.password.value.trim()
    });
  }

  if (loginPending) return <Spinner />;

  return (
    <form id="login-form" onSubmit={handleLogin}>
      <div className="mail">
        <input
          required
          id="username"
          name="username"
          type="text"
          autoComplete="username"
        />
      </div>
      <div className="password">
        <input
          required
          id="password"
          name="password"
          type="password"
          autoComplete="current-password"
        />
      </div>
      <div className="btnarea_center">
        <button type="submit" className="btn_full_thick">
          LOGIN
        </button>
      </div>
      {loginError ? <p className="errorMessage">{loginError.message}</p> : ""}
    </form>
  );
});

// --------------------- container -------------------------- //
const Login = ({ isLoggedIn }) => (
  <div className="container">
    {isLoggedIn && <Redirect push to="/" />}
    <main id="main-login">
      <header>
        <h1 className="header_logo">
          <img alt="marketprice_logo" src={HeaderLogo} />
          <span>Powered by BUYERS</span>
        </h1>
      </header>
      <Route
        exact
        path="/login/"
        render={() => (
          <Fragment>
            <LoginForm />
            <div className="login_other">
              <p className="frgt">
                パスワードを忘れた方は <Link to="/login/reset/">こちら</Link>
              </p>
            </div>
          </Fragment>
        )}
      />
      <Route exact path="/login/reset" component={ResetPass} />
    </main>
  </div>
);
const mapState = ({ user: { isLoggedIn } }) => ({
  isLoggedIn
});

export default connect(mapState)(Login);

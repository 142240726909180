export const API_RECIEVED_ITEMS = "API_RECIEVED_ITEMS",
  API_RECIEVED_SINGLE = "API_RECIEVED_SINGLE",
  API_RECIEVED_KEYWORDS = "API_RECIEVED_KEYWORDS",
  API_SEARCH_PENDING = "API_SEARCH_PENDING";

const defaultState = {
  items: [],
  keywordsList: [],
  pageData: { total: 0 }
};

export default function searchReducer(state = defaultState, { type, payload }) {
  let { searchPending, ...newState } = state;
  switch (type) {
    case API_SEARCH_PENDING:
      return { ...newState, searchPending: true };
    case API_RECIEVED_ITEMS:
      let { items, pageData } = payload;
      return {
        ...newState,
        items,
        pageData
      };
    case API_RECIEVED_SINGLE:
      return {
        ...newState,
        items: state.items.concat(payload.items)
      };
    case API_RECIEVED_KEYWORDS:
      let { keywordsList = [] } = payload;
      return {
        ...state,
        keywordsList
      };
    default:
      return state;
  }
}

import eq from "lodash/fp/eq";
import xor from "lodash/fp/xor";
import unionBy from "lodash/unionBy";
import { defer } from "lodash-es";

export const ADM_SELECTED_MAIN_TAG = "ADM_SELECTED_MAIN_TAG",
  ADM_SELECTED_TAG = "ADM_SELECTED_TAG",
  ADM_REQUEST_PENDING = "ADM_REQUEST_PENDING",
  ADM_RECIEVED_ITEMS = "ADM_RECIEVED_ITEMS",
  ADM_SELECT_ITEM = "ADM_SELECT_ITEM",
  ADM_SELECT_ITEMS = "ADM_SELECT_ITEMS",
  ADM_DESELECT = "ADM_DESELECT",
  ADM_DEL_SELECTED_ROWS = "ADM_DEL_SELECTED_ROWS",
  ADM_KEYWORD_INPUT = "ADM_KEYWORD_INPUT",
  ADM_FAILED_DELETION = "ADM_FAILED_DELETION",
  ADM_ADDED_ITEM = "ADM_ADDED_ITEM",
  ADM_UPDATE_NEW_ITEM = "ADM_UPDATE_NEW_ITEM",
  ADM_UPDATE_ITEMS = "ADM_UPDATE_ITEMS",
  ADM_UPDATE_ITEM = "ADM_UPDATE_ITEM",
  ADM_ROW_SORT = "ADM_ROW_SORT",
  SETTINGS_UPLOAD_PENDING = "SETTINGS_UPLOAD_PENDING",
  SETTINGS_UPLOAD_SUCCESS = "SETTINGS_UPLOAD_SUCCESS",
  SETTINGS_UPLOAD_FAILURE = "SETTINGS_UPLOAD_FAILURE",
  ADM_TOGGLE_PANEL = "ADM_TOGGLE_PANEL";

const defaultState = {
  items: [],
  selectedItems: [],
  sortDirection: "ASC",
  sortBy: "id",
  itemsLoaded: false,
  done: false,
  isPanelOpen: true,
  selectedMainTag: null,
  selectedTag: null,
  isPriceChanged: false
};

var newIdStock = 1;

export default function adminReducer(state = defaultState, { type, payload }) {
  switch (type) {
    case ADM_SELECTED_MAIN_TAG:
      return {
        ...state,
        selectedMainTag: payload.tag.id,
        selectedTag: state.selectedTag,
        selectedItems: [],
        sortDirection: defaultState.sortDirection,
        sortBy: defaultState.sortBy,
        itemsLoaded: false,
        done: false
      };
    case ADM_SELECTED_TAG:
      return {
        ...state,
        selectedMainTag: state.selectedMainTag,
        selectedTag: payload.tag.id,
        selectedItems: [],
        sortDirection: defaultState.sortDirection,
        sortBy: defaultState.sortBy,
        itemsLoaded: false,
        done: false
      };
    case ADM_REQUEST_PENDING:
      return { ...state, itemsLoaded: false };
    case ADM_RECIEVED_ITEMS:
      return recievedItems(state, payload);
    case ADM_ADDED_ITEM: {
      let item = payload.item;
      item.new = true;
      return {
        ...state,
        items: state.items.concat(item)
      };
    }
    case ADM_SELECT_ITEM:
      return toggleSelectedItem(state, payload);
    case ADM_SELECT_ITEMS:
      return selectItems(state, payload);
    case ADM_DESELECT:
      return { ...state, selectedItems: [] };
    case ADM_KEYWORD_INPUT:
      return changeKeyword(state, payload);
    case ADM_DEL_SELECTED_ROWS:
      return deleteRows(state);
    case ADM_UPDATE_ITEMS:
      return updateItemsData(state, payload);
    case ADM_UPDATE_ITEM:
      return updateItem(state, payload);
    case ADM_FAILED_DELETION:
      return defer(restoreItems, state, payload);
    case ADM_ROW_SORT:
      return {
        ...state,
        sortBy: payload.sortBy,
        sortDirection: payload.sortDirection
      };
    case SETTINGS_UPLOAD_PENDING:
      return { ...state, csvUploadPending: true };
    case SETTINGS_UPLOAD_SUCCESS:
      delete state.csvUploadPending;
      return { ...state };
    case SETTINGS_UPLOAD_FAILURE:
      return { ...state, apiError: payload.error, csvUploadPending: false };
    case ADM_TOGGLE_PANEL:
        return { ...state, isPanelOpen: !state.isPanelOpen };
    default:
      return state;
  }
}

function recievedItems(state, { items = [], pageData = {} }) {
  let done = pageData.page === pageData.pageCount ? { done: true } : {};
  // merging the array
  return {
    ...state,
    items: unionBy(state.items, items, "id"),
    itemsLoaded: true,
    ...done
  };
}

function toggleSelectedItem({ selectedItems, ...state }, { itemId }) {
  let found = selectedItems.find(eq(itemId));
  return {
    ...state,
    selectedItems: found
      ? xor([itemId], selectedItems)
      : selectedItems.concat([itemId]),
    isPriceChanged: false
  };
}

function selectItems(state, payload) {
  const { items } = payload
  const selectedItems = items.map(item => item.id)
  return {
    ...state,
    selectedItems
  };
}

function changeKeyword({ keyword: oldKeyword, ...state }, { keyword }) {
  return keyword ? { ...state, keyword, selectedItems: [] } : state;
}

function updateItemsData({ items: oldItems, ...state }, { itemsList }) {
  let items = oldItems.slice();

  itemsList.forEach(([index, { newData }]) => {
    Object.keys(newData).forEach(key => {
      items[index][key] = newData[key];
      if (key.startsWith("network_limit_")) items[index].network_limit_item[key.replace("network_limit_", "")] = newData[key];
    });
  });

  return { ...state, items, isPriceChanged: true };
}

function updateItem({ items: oldItems, ...state }, { id, formData }) {
  let items = oldItems.map(entry => {
    if (id === entry.id) {
      entry = { ...entry, ...formData };
    }
    return entry;
  });
  return { ...state, items };
}

function deleteRows({ selectedItems, items: oldItems, ...state }) {
  let items = oldItems.filter(entry => !selectedItems.includes(entry.id));

  return { ...state, items, selectedItems: [] };
}

function restoreItems(
  { items: oldItems, selectedItems: oldSelectedItems, ...state },
  { error, items, selectedItems }
) {
  return { ...state, items, selectedItems, apiError: error };
}

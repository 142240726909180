import { configureThunks } from "../utils/apiUtils.js";
import {
  API_RECIEVED_GENRES,
  API_RECIEVED_MAKERS,
  API_RECIEVED_MAIN_CATEGORY,
  API_RECIEVED_SUB_CATEGORY,
  API_RECIEVED_THIRD_CATEGORY,
  API_RECIEVED_LIST_CATEGORY,
  API_RECIEVED_USERS,
  SETTINGS_REQ_USERS,
  API_REQUEST_FAILED
} from "../reducers/data.reducer.js";
import { API_RECIEVED_HISTORIES } from "../reducers/histories.reducer.js";
import {
  reqGenres,
  reqMakers,
  reqSubCat,
  reqThirdCat,
  reqMainCat,
  reqListCat,
  reqHistories,
  reqUsers
} from "../auth/api.js";

const reqThunk = configureThunks({ errorCode: API_REQUEST_FAILED });

export const reqHistoriesThunk = reqThunk({
  request: reqHistories,
  resAction: ({ list, pageData }) => ({
    type: API_RECIEVED_HISTORIES,
    payload: {
      histories: list,
      pageData
    }
  })
});

export const reqGenresThunk = noParamsThunk(
  reqGenres,
  API_RECIEVED_GENRES,
  "genres"
);
export const reqMakersThunk = noParamsThunk(
  reqMakers,
  API_RECIEVED_MAKERS,
  "makers"
);
export const reqMainCatThunk = noParamsThunk(
  reqMainCat,
  API_RECIEVED_MAIN_CATEGORY,
  "mainCat"
);
export const reqSubCatThunk = noParamsThunk(
  reqSubCat,
  API_RECIEVED_SUB_CATEGORY,
  "subCat"
);
export const reqThirdCatThunk = noParamsThunk(
  reqThirdCat,
  API_RECIEVED_THIRD_CATEGORY,
  "thirdCat"
);
export const reqListCatThunk = noParamsThunk(
  reqListCat,
  API_RECIEVED_LIST_CATEGORY,
  "listCat"
);

export const reqUsersThunk = params => async dispatch => {
  dispatch({
    type: SETTINGS_REQ_USERS
  });
  try {
    var { list, results: { pageData } } = await reqUsers(params);
  } catch (error) {
    return dispatch({
      type: API_REQUEST_FAILED,
      payload: {
        error: error.message
      }
    });
  }

  return dispatch({
    type: API_RECIEVED_USERS,
    payload: {
      list,
      pageData
    }
  });
};

function noParamsThunk(request, type, key) {
  return reqThunk({
    request,
    resAction: results => ({
      type,
      payload: {
        [key]: results
      }
    })
  });
}

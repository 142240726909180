import appConfig from "../appConfig.js";
import { getToken } from "./storage.js";

export function login(userInput) {
  return extractData(
    fetch(appConfig.apiURL + "/users/login", {
      method: "POST",
      body: Object.keys(userInput).reduce(
        (o, key) => (o.append(key, userInput[key]), o), //eslint-disable-line no-sequences
        new FormData()
      )
    })
  );
}

export function logout(username) {
  return postItems("/users/logout", { username });
}

const requiredParam = name => {
  throw new Error("リクエスト出来ませんでした。必要データは: " + name);
};

/** @param {{keyword: string}|string} keyword */
export function reqKeyword(keyword) {
  let keywordObj = typeof keyword === "string" ? { keyword } : keyword;
  let url = "/items";
  return reqItems(url, keywordObj);
}

/** @param {number} id */
export function reqId(id = requiredParam("id")) {
  let url = `/items/view/${id}`;
  return reqItems(url);
}

export function reqHistories(params) {
  return reqItems("/histories", params);
}

export function reqKeywordsList() {
  return reqItems("/search-histories");
}

export function reqGenres() {
  return reqItems("/genres");
}

export function reqMakers() {
  return reqItems("/makers");
}

export function reqMainCat() {
  return reqItems("/main-categories");
}

export function reqConditions() {
  return reqItems("/conditions");
}

export function reqSubCat() {
  return reqItems("/sub-categories");
}

export function reqThirdCat() {
  return reqItems("/third-categories");
}

export function reqListCat() {
  return reqItems("/list-categories");
}

export function reqUsers(params) {
  return reqItems("/users", params);
}

export const addCategory = {
  main_category: (dataObj = requiredParam("dataObj")) =>
    postItems("/main-categories/add", dataObj),
  sub_category: (dataObj = requiredParam("dataObj")) =>
    postItems("/sub-categories/add", dataObj),
  third_category: (dataObj = requiredParam("dataObj")) =>
    postItems("/third-categories/add", dataObj),
  list_category: (dataObj = requiredParam("dataObj")) =>
    postItems("/list-categories/add", dataObj),
  genre: (dataObj = requiredParam("dataObj")) =>
    postItems("/genres/add", dataObj),
  maker: (dataObj = requiredParam("dataObj")) =>
    postItems("/makers/add", dataObj)
};

export const updateCategory = {
  main_category: (id = requiredParam("id"), dataObj) =>
    postItems("/main-categories/edit/" + id, dataObj),
  sub_category: (id = requiredParam("id"), dataObj) =>
    postItems("/sub-categories/edit/" + id, dataObj),
  third_category: (id = requiredParam("id"), dataObj) =>
    postItems("/third-categories/edit/" + id, dataObj),
  list_category: (id = requiredParam("id"), dataObj) =>
    postItems("/list-categories/edit/" + id, dataObj),
  genre: (id = requiredParam("id"), dataObj) =>
    postItems("/genres/edit/" + id, dataObj),
  maker: (id = requiredParam("id"), dataObj) =>
    postItems("/makers/edit/" + id, dataObj)
};

export const deleteCategory = {
  main_category: (idItem = requiredParam("id")) =>
    postItems("/main-categories/delete/", idItem),
  sub_category: (idItem = requiredParam("id")) =>
    postItems("/sub-categories/delete/", idItem),
  third_category: (idItem = requiredParam("id")) =>
    postItems("/third-categories/delete/", idItem),
  list_category: (idItem = requiredParam("id")) =>
    postItems("/list-categories/delete/", idItem),
  genre: (idItem = requiredParam("id")) => postItems("/genres/delete/", idItem),
  maker: (idItem = requiredParam("id")) => postItems("/makers/delete/", idItem)
};

export function reqExportCSV() {
  let token = getToken();
  let paramStr = queryParamsToString({
    token
  });
  return fetch(appConfig.apiURL + "/items/csv-export?" + paramStr);
}

export function importCSV(dataObj = requiredParam("dataObj")) {
  return postItems("/items/csv-import", dataObj);
}

/** @param {number} idItem 消したいアイテムにID */
export function deleteItem(idItem = requiredParam("id")) {
  return postItems("/items/delete", idItem);
}

/** @param {Object} dataObj 変わりたいキーと価のみ */
export function addItem(dataObj = requiredParam("dataObj")) {
  return postItems("/items/add", dataObj);
}

/** @param {number} id
 * @param {Object} dataObj 変わりたいキーと価のみ */
export function updateItem(id = requiredParam("id"), dataObj) {
  return postItems("/items/edit/" + id, dataObj);
}

/** @param {Object} dataObj 変わりたいキーと価のみ */
export function addCondition(dataObj = requiredParam("dataObj")) {
  return postItems("/conditions/add", dataObj);
}

/** @param {number} id
 * @param {Object} dataObj 変わりたいキーと価のみ */
export function updateCondition(id = requiredParam("id"), dataObj) {
  return postItems("/conditions/edit/" + id, dataObj);
}

export function reqDeleteCondition(idItem) {
  return postItems("/conditions/delete", idItem);
}

export function updateUser(dataObj) {
  return postItems("/users/edit/", dataObj);
}

export function deleteUser(usernameItem = requiredParam("usernameItem")) {
  return postItems("/users/delete", usernameItem);
}

export function addUser(userData) {
  return postItems("/users/add", userData);
}

function reqItems(url, params = {}) {
  let token = getToken();
  let paramStr = queryParamsToString({
    ...params,
    token
  });
  return extractData(fetch(appConfig.apiURL + url + "?" + paramStr));
}

function postItems(url, params = {}) {
  let token = getToken();
  let paramStr = queryParamsToString({
    token
  });
  return extractData(
    fetch(appConfig.apiURL + url + "?" + paramStr, {
      method: "POST",
      body: Object.keys(params).reduce(
        (o, key) => (o.append(key, params[key]), o), //eslint-disable-line no-sequences
        new FormData()
      )
    })
  );
}

function queryParamsToString(params = {}) {
  return Object.keys(params)
    .reduce((acc, key) => [...acc, `${key}=${params[key]}`], [])
    .join("&");
}

async function extractData(promise) {
  let Response = await promise;
  if (Response.ok === false) {
    const { message } = await Response.json();
    throw new Error(`${Response.status} ${Response.statusText} ${message || ''}`);
  } else {
    return Response.json();
  }
}

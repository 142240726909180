import React, { Component, Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Select, { Creatable } from "react-select";
import { connect } from "react-redux";
import take from "lodash/fp/take";

import "react-select/dist/react-select.css";

import { keywordSearchThunk, reqKeywordsListThunk } from "./Search.actions.js";
import AsyncComponent from "../../utils/AsyncComponent.js";

const ResultsList = AsyncComponent(() => import("./ResultsList.js"));
const HistoryTable = AsyncComponent(() => import("./HistoryTable.js"));

const mapDispatch = {
  reqKeyword: keywordSearchThunk,
  reqKeywordsList: reqKeywordsListThunk
};

class keyFormClass extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: "",
      select: "",
			multiValue: []
    };
  }

  componentWillMount() {
    if (this.props.keywordsList.length === 0) this.props.reqKeywordsList();
  }

  onSubmit = event => {
    event.preventDefault();
    // send search keyword
    let params = {
      key: this.state.keyword.trim()
    };
    if (this.state.select !== null) params.genre = this.state.select.id;
    this.props.reqKeyword(params);
  };

  onKeywordChange = e => this.setState({ keyword: e.target.value });

  clickKeyword = (props) => e => {
    e.preventDefault();
    let multiValue = this.state.multiValue !== []
      ? [...this.state.multiValue, props]
      : [props];

    this.setState({
      multiValue: multiValue,
      keyword: multiValue.map(item => item.label).join(" ")
    });
  };

  onGenreChange = newOption =>
    this.setState({
      select: newOption
    });


	handleOnChange = value => {
		this.setState({
      multiValue: value,
      keyword: value.map(item => item.label).join(" ")
     });
	}

  render() {
    let { genres, searchPending, subCat } = this.props;
    let keywordsList = take(8)(
      this.props.keywordsList.filter(entry => entry.count > 1)
    );
    subCat = subCat.map(item => ({'label': item.name, 'value': item.name}));

    return (
      <form onSubmit={this.onSubmit} id="keySearch">
        <h2>キーワードから探す </h2>
        <label>検索対象を選択してください。</label>
        <Select
          options={genres}
          valueKey="id"
          labelKey="name"
          value={this.state.select}
          onChange={this.onGenreChange}
          isLoading={genres.length === 0}
        />
        <label>機種名や型番などを入力してください。</label>
        <div className="input_wrap">
          <Creatable
  					multi
  					options={subCat}
  					onChange={this.handleOnChange}
  					value={this.state.multiValue}
  				/>
        </div>
        <label>よく使用するキーワード</label>
        <ul id="keyword-list">
          {keywordsList.map(({ text }) => (
            <li key={text}>
              <button type="button" onClick={this.clickKeyword({label: text, value: text})}>
                {text}
              </button>
            </li>
          ))}
        </ul>
        <div className="btnarea_center">
          <button type="submit" disabled={searchPending}>
            検索する
          </button>
        </div>
        {searchPending && <Redirect to="/search" />}
      </form>
    );
  }
}

const KeyForm = connect(
  ({
    data: { genres, mainCat, subCat },
    search: { searchPending, keywordsList }
  }) => ({
    genres,
    mainCat,
    subCat,
    searchPending,
    keywordsList
  }),
  mapDispatch
)(keyFormClass);

class stepFormClass extends Component {
  constructor(props) {
    super(props);

    this.state = {
      genre: "",
      mainCat: "",
      subCat: "",
      listStepClass : 1
    };
  }

  onSubmit = event => {
    event.preventDefault();
    let params = {};
    if (this.state.genre) params.genre = this.state.genre.id;
    if (this.state.mainCat) params.main_category = this.state.mainCat.id;
    if (this.state.subCat) params.sub_category = this.state.subCat.id;

    this.props.reqKeyword(params);
  };

  onGenreChange = newOpt =>
    this.setState({
      genre: newOpt
    });

  onMainCatChange = newOpt =>
    this.setState({
      mainCat: newOpt
    });

  onSubCatChange = newOpt =>
    this.setState({
      subCat: newOpt
    });

  onChangeClass = (cNumber, selectedCategory, selectedItem) => e => {
    e.preventDefault();
    this.setState({
      listStepClass: cNumber,
    });
    if(selectedCategory === 'gerne'){this.setState({genre: selectedItem, mainCat: ''});}
    if(selectedCategory === 'main'){this.setState({mainCat: selectedItem, subCat: ''});}
    if(selectedCategory === 'sub'){this.setState({subCat: selectedItem});}
  }
  onBackSelector = (cNumber, selectedCategory) => e => {
    e.preventDefault();
    this.setState({
      listStepClass: cNumber
    });
    if(selectedCategory === 'main'){this.setState({mainCat: ''});}
    if(selectedCategory === 'sub'){this.setState({subCat: ''});}
  }


  render() {
    let { genres, mainCat, subCat, searchPending } = this.props;
    return (
      <form id="stepSearch" onSubmit={this.onSubmit}>
        <h2>３ステップで探す</h2>
        <p>商品ジャンル選択が必須。以後は未選択でも検索できます。</p>


        <section className="step_form">
        <div className="category">{this.state.genre && this.state.genre.name || '下記から選択してください'}{this.state.mainCat && ' > ' + this.state.mainCat.name}{this.state.subCat && ' > ' + this.state.subCat.name}</div>

        <div className="btnarea_right">
          <button disabled={searchPending}>検索する</button>
            {searchPending && <Redirect push to="/search" />}
        </div>
      </section>
      <section className="step_select on">
          <div id="target_list_step" className={`list_wrap list_step_0${this.state.listStepClass}`}>
            <div className="step_01">
              <div className="btnarea">
                <div className="ttl">デバイスの種別</div>
              </div>
              <ul>
                {genres.map(item => {
                  return (
                  <li key={item.name}>
                    <p
                      onClick={this.onChangeClass(2, 'gerne', item)}
                      className={this.state.genre.id === item.id && 'current genre_select' || 'genre_select'}
                      id={'genre_'+item.id}
                    >
                      {item.name}
                    </p>
                  </li>
                )
                })}
              </ul>
            </div>
            <div className="step_02">
              <div className="btnarea">
                <div className="ttl">メーカー別機種</div>
                <div className="back_02" onClick={this.onBackSelector(1, 'main')}>戻る</div>
              </div>
              <ul>
                {mainCat.map(item => {
                  if(item.parent_id === this.state.genre.id){
                    return (
                      <li key={item.name}>
                        <p
                          onClick={this.onChangeClass(3, 'main', item)}
                          className={this.state.mainCat.id === item.id && 'current mainCat_select' || 'mainCat_select'}
                          id={'mainCat_'+item.id}
                        >
                          {item.name}
                        </p>
                      </li>
                    )
                  }
                })}
              </ul>
            </div>
            <div className="step_03">
              <div className="btnarea">
                <div className="ttl">機種名</div>
                <div className="back_03" onClick={this.onBackSelector(2, 'sub')}>戻る</div>
              </div>
              <ul>
                {subCat.map(item => {
                  if(item.parent_id === this.state.mainCat.id){
                    return (
                      <li key={item.name}>
                        <p
                          onClick={this.onChangeClass(3, 'sub', item)}
                          className={this.state.subCat.id === item.id && 'current subCat_select' || 'subCat_select'}
                          id={'subCat_'+item.id}
                        >
                          {item.name}
                        </p>
                      </li>
                    )
                  }
                })}
              </ul>
            </div>
          </div>
        </section>
      </form>

    );
  }
}
const StepForm = connect(
  ({ data: { genres, mainCat, subCat }, search: { searchPending } }) => ({
    genres,
    mainCat,
    subCat,
    searchPending
  }),
  mapDispatch
)(stepFormClass);

// Wrapper Component //
const Search = () => (
  <Fragment>
    <section id="inputs">
      <KeyForm />
      <StepForm />
    </section>
    {/* childrenが変ってる時にスペースを保存するため */}
    <div id="bottom-section">
      <Switch>
        <Route exact path="/search" component={ResultsList} />
        <Route path="/" component={HistoryTable} />
      </Switch>
    </div>
  </Fragment>
);

export default Search;

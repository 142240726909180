import { combineReducers } from "redux";
import loginReducer from "./login.reducer.js";
import searchReducer from "./search.reducer.js";
import historiesReducer from "./histories.reducer.js";
import dataReducer from "./data.reducer.js";
import adminReducer from "./admin.reducer.js";

import appConfig from "../appConfig.js";

let defaultState = appConfig;

function rootReducer(state = defaultState, { type, payload }) {
  switch (type) {
    default:
      return state;
  }
}
export default combineReducers({
  appConfig: rootReducer,
  admin: adminReducer,
  data: dataReducer,
  user: loginReducer,
  search: searchReducer,
  histories: historiesReducer
});

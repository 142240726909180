/** Thunk factory */
export function configureThunks({ errorCode }) {
  return function reqThunk({
    request,
    resAction,
    resError = dispatchError(errorCode),
    pendingCode = false
  }) {
    return params => async (dispatch, getState) => {
      if (pendingCode) dispPending(pendingCode, dispatch);
      try {
        const { results } = await request(params);
        if (results.status === "failure") throw new Error(results.message);
        return dispatch(resAction(results));
      } catch (error) {
        return dispatch(resError(error));
      }
    };
  };
}

function dispatchError(errorCode) {
  return function(error) {
    return {
      type: errorCode,
      payload: {
        error: error.message
      }
    };
  };
}

function dispPending(pendingCode, dispatch) {
  dispatch({
    type: pendingCode
  });
}

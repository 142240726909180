import { Component } from "react";
import { connect } from "react-redux";

import {
  reqGenresThunk,
  reqMakersThunk,
  reqMainCatThunk,
  reqSubCatThunk,
  reqThirdCatThunk,
  reqListCatThunk,
  reqHistoriesThunk
} from "../pages/data.actions.js";
import { reqConditionsThunk } from "../pages/settings/settings.actions.js";
import { selectMainTag, selectTag } from "../pages/admin/admin.actions.js";

class AppDataFetcher extends Component {
  componentDidMount() {
    let {
      genres,
      makers,
      mainCat,
      subCat,
      thirdCat,
      listCat,
      list,
      conditions,
      getConditions,
      reqGenres,
      reqMakers,
      reqMainCat,
      selectMainTag,
      selectedMainTag,
      reqSubCat,
      reqThirdCat,
      reqListCat,
      selectTag,
      selectTagFlag,
      selectedTag,
      reqHist,
      reqConds
    } = this.props;
    if (genres.length === 0) reqGenres();
    if (makers.length === 0) reqMakers();
    if (mainCat.length === 0) reqMainCat();
    else if (selectedMainTag === void 0)
      this.props.selectMainTag({id: null}); //ADM_SELECTED_MAIN_TAG
    if (subCat.length === 0) reqSubCat();
    if (thirdCat.length === 0) reqThirdCat();
    if (listCat.length === 0) reqListCat();
    else if (selectedTag === void 0 && selectTagFlag)
      this.props.selectTag({id: null}); //ADM_SELECTED_TAG
    if (list.length === 0) reqHist({ sortKey: "modified", sortVal: "desc" });
    if (getConditions && conditions.length === 0) reqConds();
  }

  render() {
    return this.props.children;
  }
}
const mapStateToProps = ({
  data: { genres, makers, mainCat, subCat, thirdCat, listCat, conditions },
  histories: { list },
  admin: { selectedMainTag, selectedTag }
}) => ({
  genres,
  makers,
  mainCat,
  subCat,
  thirdCat,
  listCat,
  list,
  conditions,
  selectedMainTag,
  selectedTag
});
const mapDispatch = {
  reqGenres: reqGenresThunk,
  reqMakers: reqMakersThunk,
  reqMainCat: reqMainCatThunk,
  reqSubCat: reqSubCatThunk,
  reqThirdCat: reqThirdCatThunk,
  reqListCat: reqListCatThunk,
  reqHist: reqHistoriesThunk,
  reqConds: reqConditionsThunk,
  selectMainTag,
  selectTag
};

export default connect(mapStateToProps, mapDispatch)(AppDataFetcher);

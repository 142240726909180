import { configureThunks } from "../../utils/apiUtils.js";
import {
  API_RECIEVED_ITEMS,
  API_RECIEVED_SINGLE,
  API_RECIEVED_KEYWORDS,
  API_SEARCH_PENDING
} from "../../reducers/search.reducer.js";
import { API_REQUEST_FAILED } from "../../reducers/data.reducer.js";
import { reqKeyword, reqId, reqKeywordsList } from "../../auth/api.js";

const reqThunk = configureThunks({ errorCode: API_REQUEST_FAILED });

export const reqByIdThunk = reqThunk({
  request: reqId,
  pendingFlag: true,
  resAction: results => ({
    type: API_RECIEVED_SINGLE,
    payload: {
      items: results
    }
  })
});

var savedParams = {};
export const keywordSearchThunk = formParams => async (dispatch, getState) => {
  dispatch({
    type: API_SEARCH_PENDING
  });

  let { repeatSearch, ...params } = formParams;
  if (repeatSearch) params = { ...savedParams, page: params.page };
  else savedParams = params;

  try {
    const { results } = await reqKeyword(params);
    if (results.status === "failure") throw new Error(results.message);

    let { list, pageData } = results;
    return dispatch({
      type: API_RECIEVED_ITEMS,
      payload: {
        items: list,
        pageData
      }
    });
  } catch (error) {
    return dispatch({
      type: API_REQUEST_FAILED,
      payload: {
        error: error.message
      }
    });
  }
};

export const reqKeywordsListThunk = reqThunk({
  request: reqKeywordsList,
  resAction: results => ({
    type: API_RECIEVED_KEYWORDS,
    payload: {
      keywordsList: results
    }
  })
});

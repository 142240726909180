import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import "./css/app.css";
import AsyncComponent from "./utils/AsyncComponent.js";
import Login from "./pages/login/Login.js";
import Top from "./pages/top/Top.js";
import Notifier from "./components/Notifier.js";

const Admin = AsyncComponent(() => import("./pages/admin/Admin.js"));
const Settings = AsyncComponent(() => import("./pages/settings/Settings.js"));

const App = ({ isLoggedIn, isAdmin, isEditor, ifLoggedIn }) => {
  return (
    <div className="App">
      <Switch>
        <Route path="/login/" component={Login} />
        <Route
          path="/"
          render={ifLoggedIn(props => (
            <Switch>
              {(isEditor || isAdmin) && (
                <Route path="/admin/" component={Admin} />
              )}
              {isAdmin && <Route path="/settings" component={Settings} />}
              <Route component={Top} />
            </Switch>
          ))}
        />
      </Switch>
      <footer>Copyright © 2018 BUYERS.com All Rights Reserved</footer>
      <Notifier />
    </div>
  );
};

const mapStateToProps = ({ user: { isLoggedIn, isAdmin, isEditor } }) => {
  const ifLoggedIn = component =>
    isLoggedIn ? component : () => <Redirect push to="/login/" />;
  return {
    isLoggedIn,
    isAdmin,
    isEditor,
    ifLoggedIn
  };
};

// 注意！withRouterがないとリフレッシュしない！
export default withRouter(connect(mapStateToProps)(App));

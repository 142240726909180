import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducer from "./reducers/index.js";
import "core-js/es6/symbol";
import "core-js/fn/symbol/iterator";
import "core-js/fn/string/includes";
import "core-js/fn/array/includes";
import "core-js/fn/array/find";
import "core-js/fn/array/find-index";
import "core-js/fn/array/fill";

import "./css/index.css";
import "./css/css-reset.min.css";

import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

const composeEnhancers =
  (process.env.NODE_ENV !== "production" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;
const store = createStore(
  reducer,
  {},
  composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();

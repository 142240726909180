import { getTokenObject } from "../auth/storage.js";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILURE = "LOGIN_FAILURE",
  LOGIN_LOGOUT = "LOGIN_LOGOUT",
  LOGIN_SUBMIT = "LOGIN_SUBMIT",
  LOGIN_REGISTER_REQ = "LOGIN_REGISTER_REQ",
  LOGIN_REGISTER_SUCCESS = "LOGIN_REGISTER_SUCCESS",
  LOGIN_REGISTER_FAILURE = "LOGIN_REGISTER_FAILURE",
  LOGIN_CONFIRM_SUCCESS = "LOGIN_CONFIRM_SUCCESS",
  LOGIN_CONFIRM_FAILURE = "LOGIN_CONFIRM_FAILURE",
  LOGIN_RESETPASS_SUBMIT = "LOGIN_RESETPASS_SUBMIT",
  LOGIN_RESETPASS_SUCCESS = "LOGIN_RESETPASS_SUCCESS",
  LOGIN_RESETPASS_FAILURE = "LOGIN_RESETPASS_FAILURE";

const defaultState = {
  isAdmin: false,
  ...getTokenObject()
};

export default function loginReducer(state = defaultState, { type, payload }) {
  switch (type) {
    case LOGIN_SUBMIT:
      return { ...state, loginPending: true };
    case LOGIN_SUCCESS:
      return loginSuccess(state, payload);
    case LOGIN_FAILURE: {
      let { loginPending, token, ...newState } = state;
      return {
        ...newState,
        loginError: payload.loginError,
        isLoggedIn: false
      };
    }
    case LOGIN_LOGOUT: {
      let { token, ...newState } = state;
      return {
        ...newState,
        isLoggedIn: false
      };
    }
    case LOGIN_REGISTER_REQ:
      return { ...state, registerPending: true };
    case LOGIN_REGISTER_SUCCESS:
      return registerSucces(state, payload);
    case LOGIN_REGISTER_FAILURE:
      return {
        ...state,
        registerError: payload.registerError,
        registerPending: false
      };
    case LOGIN_CONFIRM_SUCCESS:
      return confirmationSucces(state, payload);
    case LOGIN_CONFIRM_FAILURE:
      return {
        ...state,
        registerError: payload.registerError,
        registerPending: false
      };
    case LOGIN_RESETPASS_SUCCESS:
      return {
        ...state,
        resetSuccess: true
      };
    case LOGIN_RESETPASS_FAILURE:
      return {
        ...state,
        resetError: payload.resetError
      };
    default:
      return state;
  }
}

export function loginSuccess(
  { loginError, loginPending, ...state },
  { username, token, level }
) {
  return {
    ...state,
    isLoggedIn: true,
    username,
    token,
    isAdmin: level == 9, // eslint-disable-line eqeqeq
    isEditor: level == 2 // eslint-disable-line eqeqeq
  };
}

function registerSucces(
  { registerPending, registerError, userData: oldData, ...state },
  { userData }
) {
  return { ...state, userData: { ...oldData, ...userData }, confirmed: false };
}

function confirmationSucces(
  { registerPending, registerError, userData: oldData, ...state },
  { userData }
) {
  return { ...state, userData: { ...oldData, ...userData }, confirmed: true };
}

import {
  signIn,
  resetPassword,
  registerUser,
  confirmUser
} from "../../auth/cognito.js";
import { login, logout } from "../../auth/api.js";
import { saveToken, removeToken } from "../../auth/storage.js";
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_SUBMIT,
  LOGIN_LOGOUT,
  LOGIN_REGISTER_REQ,
  LOGIN_REGISTER_SUCCESS,
  LOGIN_REGISTER_FAILURE,
  LOGIN_CONFIRM_SUCCESS,
  LOGIN_CONFIRM_FAILURE,
  LOGIN_RESETPASS_SUBMIT,
  LOGIN_RESETPASS_SUCCESS,
  LOGIN_RESETPASS_FAILURE
} from "../../reducers/login.reducer.js";

export const authRequestThunk = userInput => async dispatch => {
  // UIにローディングアニメーション見せたい場合
  dispatch({
    type: LOGIN_SUBMIT
  });

  try {
    let { results } = await login(userInput);
    if (results.status === "success") {
      let { token, level } = results;
      saveToken({ token, level });
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          username: userInput.username,
          token,
          level
        }
      });
    } else throw new Error(results.message);
  } catch (loginError) {
    dispatch({
      type: LOGIN_FAILURE,
      payload: {
        loginError: loginError
      }
    });
  }
};

export const handleLogout = e => async (dispatch, getState) => {
  e.preventDefault();
  removeToken();
  let username = getState().user.username;
  dispatch({
    type: LOGIN_LOGOUT
  });
  try {
    await logout(username);
  } catch (error) {}
};

export const confirmationThunk = data => async dispatch => {
  dispatch({
    type: LOGIN_REGISTER_REQ
  });
  try {
    let userData = await confirmUser(data);
    dispatch({
      type: LOGIN_CONFIRM_SUCCESS,
      payload: { userData }
    });
  } catch (err) {
    dispatch({
      type: LOGIN_CONFIRM_FAILURE,
      payload: {
        registerError: err.message
      }
    });
  }
};

export const resetPassThunk = username => async dispatch => {
  try {
    await resetPassword(username);
    dispatch({
      type: LOGIN_RESETPASS_SUCCESS
    });
  } catch (err) {
    dispatch({
      type: LOGIN_RESETPASS_FAILURE,
      payload: {
        resetError: err.message
      }
    });
  }
};

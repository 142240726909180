export const hosts = {
  LOCAL: "http://localhost:8080",
  PRODUCTION: "https://price-search.jp/api"
};

export const paths = {
  NO_IMG: "/img/no_img.png"
};

export const isLocal = window.location.host.includes("localhost");

export const host = isLocal ? hosts.LOCAL : hosts.PRODUCTION;

/* --{ ここからaws-sdkを読み込む }-- */
import AWS from "aws-sdk";
import {
  CognitoUserPool,
  CognitoUser,
  CognitoUserAttribute,
  AuthenticationDetails
} from "amazon-cognito-identity-js";

import AppConfig from "../appConfig.js";

AWS.config.update({
  region: AppConfig.region
});

export const userPool = new CognitoUserPool({
  UserPoolId: AppConfig.UserPoolId,
  ClientId: AppConfig.ClientId
});

export async function signIn({ username, password }) {
  const { IdentityPoolId } = AppConfig;

  var authenticationDetails = new AuthenticationDetails({
    Username: username,
    Password: password
  });

  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool
  });
  let identityId = await authenticateUser(cognitoUser, authenticationDetails);
  const userData = await buildUserObj(cognitoUser);
  // TODO ローカルストレージに保存する処理
  return { userData, identityId };
}

/** Reduxのthunkで使うために戻り値はPromiseに包む */
function authenticateUser(cognitoUser, authenticationDetails) {
  return new Promise((resolve, reject) => {
    /* -- { ここまでアカウント認証に使用するものを準備 }-- */
    /* -- { ここからログイン処理 }-- */
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function(result) {
        /* -- { ここからアカウント認証に成功した場合の処理 }-- */
        let accessToken = result.getIdToken().getJwtToken();
        console.log("アクセストークン=", { accessToken });

        const LoginsKey = `cognito-idp.${AWS.config.region}.amazonaws.com/${
          AppConfig.UserPoolId
        }`;

        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: AppConfig.IdentityPoolId,
          Logins: {
            [LoginsKey]: accessToken
          }
        });
        AWS.config.credentials.refresh(err => {
          if (err) {
            console.log("Credentials not refreshed");
            reject(err);
          } else {
            /* -- { ここからユーザーIDを取得 }-- */
            var identityId = AWS.config.credentials.identityId;
            console.log("id: " + identityId);
            resolve(identityId);
          }
        });
      },
      onFailure: function(err) {
        /* -- { ここからアカウント認証に失敗した場合の処理 }-- */
        reject(err);
      }
      // mfaRequired: function(codeDeliveryDetails) {
      // MFA is required to complete user authentication.
      // Get the code from user and call
      // cognitoUser.sendMFACode(mfaCode, this);
      // },
      // newPasswordRequired: function(userAttributes, requiredAttributes) {
      // User was signed up by an admin and must provide new
      // password and required attributes, if any, to complete
      // authentication.
      // userAttributes: object, which is the user's current profile. It will list all attributes that are associated with the user.
      // Required attributes according to schema, which don’t have any values yet, will have blank values.
      // requiredAttributes: list of attributes that must be set by the user along with new password to complete the sign-in.
      // Get these details and call
      // newPassword: password that user has given
      // attributesData: object with key as attribute name and value that the user has given.
      // cognitoUser.completeNewPasswordChallenge(newPassword, attributesData, this);
      // }
    });
  });
}

/** @param {{email: string, password: string}} userInput */
export function registerUser(userInput) {
  var attributeList = [
    new CognitoUserAttribute({
      Name: "email",
      Value: userInput.email
    }),
    new CognitoUserAttribute({
      Name: "name",
      Value: userInput.name
    })
  ];

  return new Promise((resolve, reject) => {
    userPool.signUp(
      userInput.username,
      userInput.password,
      attributeList,
      null,
      (err, result) => {
        if (err) return reject(err);

        console.log("User registered", result);
        return resolve(result);
      }
    );
  });
}

export function confirmUser(confirmationData) {
  var userData = {
    Username: confirmationData.username,
    Pool: userPool
  };

  var cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.confirmRegistration(confirmationData.code, true, function(
      err,
      result
    ) {
      if (err) return reject(err);

      return resolve(result);
    });
  });
}

/** Redux stateで保存するためにユーザーだたのオブジェクト */
function buildUserObj(cognitoUser) {
  return new Promise((resolve, reject) => {
    /* -- { ここからログインユーザーの情報を取得し }-- */
    return cognitoUser.getUserAttributes((err, result) => {
      if (err) {
        return reject(err);
      } else {
        let userProfileObject = result.reduce((acc, entry) => {
          let key = entry.getName();
          // カストム属性あるなら、消す
          if (key.includes("custom:")) key = key.slice(7);

          return { ...acc, [key]: entry.getValue() };
        }, {});
        return resolve(userProfileObject);
      }
    });
  });
}

/** Reduxのthunkで使うために戻り値はPromiseに包む */
export const resetPassword = ({ username }) => {
  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool
  });
  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: function(data) {
        console.log("CodeDeliveryData from forgotPassword: " + data);
        resolve();
      },
      onFailure: function(err) {
        reject(err);
      },
      //Optional automatic callback
      inputVerificationCode: function(data) {
        console.log("Code sent to: " + data);
        var verificationCode = prompt("メールアドレスに検証コードを送りました。メールを確認して検証コードを入力してください。 ", "");
        if (verificationCode.length){
          var newPassword = prompt("新しいパスワードを入力してください。 ", "");
          cognitoUser.confirmPassword(verificationCode, newPassword, {
            onSuccess() {
              console.log("Password confirmed!");
              alert("パスワードを変更しました。");
              window.location.href='/#/login';
            },
            onFailure(err) {
              console.log("Password not confirmed!");
            }
          });
        }
      }
    });
  });
};

import React, { Component } from "react";
import { connect } from "react-redux";
import cx from "classnames";

class Notifier extends Component {
  state = {
    errorList: []
  };

  remove = i => () =>
    this.setState({ errorList: [...this.state.errorList.splice(i, 1)] });

  componentWillReceiveProps(nextProps) {
    let { adminError, dataError, logError } = nextProps,
      res = [];
    if (adminError) res.push(adminError);
    if (dataError) res.push(dataError);
    if (logError) res.push(logError);

    this.setState(state => ({ errorList: state.errorList.concat(res) }));
  }
  render() {
    return (
      <div id="notifier">
        {this.state.errorList.map((entry, i) => (
          <ErrorMessage
            key={"error" + i}
            message={typeof entry === "string" ? entry : entry.message}
            remove={this.remove(i)}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = ({
  admin: { apiError: adminError },
  data: { apiError: dataError },
  histories: { error: logError }
}) => ({ adminError, dataError, logError });

export default connect(mapStateToProps)(Notifier);

class ErrorMessage extends Component {
  state = {
    entering: true
  };

  componentDidMount() {
    setTimeout(() => this.setState({ entering: false }), 500);
    setTimeout(() => this.dismiss(), 15000);
  }

  dismiss = e => {
    this.setState({ entering: true });
    setTimeout(() => this.props.remove(), 1000);
  };

  render() {
    return (
      <div
        onClick={this.dismiss}
        className={cx("error-message", { entering: this.state.entering })}
      >
        <h4>Error</h4>
        <p>{this.props.message}</p>
      </div>
    );
  }
}

import React from "react";
import { Route, Switch, Link } from "react-router-dom";

import "../../css/search.css";

import AppDataFetcher from "../../utils/AppDataFetcher.js";
import AsyncComponent from "../../utils/AsyncComponent.js";
import Header from "../../components/Header.js";
import Search from "./Search.js";
const Detail = AsyncComponent(() => import("../detail/Detail.js"));

const Top = () => (
  <AppDataFetcher>
    <Header />
    <div className="bread">
      <ul>
        <li><Link to="/">TOp</Link></li>
        <li><p>価格を検索.</p></li>
      </ul>
    </div>
    <main id="main-search">
      <Switch>
        <Route path="/detail/:id" component={Detail} />
        <Route path="/" component={Search} />
      </Switch>
    </main>
  </AppDataFetcher>
);

export default Top;
